

























































import { resetPasswordApi, userLoginApi } from '@/api/user'
import { showError } from '@/utils/common'
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { Form, FormItem } from 'element-ui'
import Main from '@/components/Main.vue'
import { resPass } from '@/utils/res'
import { useUserStore } from '@/pinia/modules/user'
export default defineComponent({
    components: {
        Main,
        [Form.name]: Form,
        [FormItem.name]: FormItem
    },
    setup(props, { root }) {
        const userStore = useUserStore()
        const { hash_key, email } = root.$route.query
        if (!hash_key || !email) {
            root.$message.error(<string>root.$i18n.t('passwordReset.errorMsg1'))
            root.$router.replace('/404')
        }
        const formInfo = reactive({
            password: '',
            repeatPassword: ''
        })
        const formRef = ref()

        const formRules = {
            password: [
                { required: true, message: root.$i18n.t('passwordReset.formRule1'), trigger: ['blur', 'change'] },
                { min: 8, message: root.$i18n.t('passwordReset.formRule3'), trigger: 'blur' },
                {
                    required: true,
                    validator: (rule: any[], value: string, callback: any) => {
                        if (!resPass.test(value)) {
                            callback(new Error(<string>root.$i18n.t('passwordReset.formRule4')))
                        } else {
                            callback()
                        }
                    },
                    trigger: 'blur'
                }
            ],
            repeatPassword: [
                {
                    required: true,
                    validator: (rule: any[], value: string, callback: any) => {
                        if (value === '') {
                            callback(new Error(<string>root.$i18n.t('passwordReset.formRule2')))
                        } else if (value !== formInfo.password) {
                            callback(new Error(<string>root.$i18n.t('passwordReset.formRule5')))
                        } else {
                            callback()
                        }
                    },
                    trigger: 'blur'
                }
            ]
        }

        const iconInfo = reactive({
            password: true,
            repeatPassword: true
        })

        const submitLoad = ref(false)
        const submitForm = () => {
            formRef.value
                .validate()
                .then(async () => {
                    submitLoad.value = true
                    try {
                        await resetPasswordApi({
                            email: email as string,
                            password: formInfo.password,
                            hash_key: hash_key as string
                        })
                        root.$message.success(<string>root.$i18n.t('setSuccess'))

                        const token = await userLoginApi({
                            username: email as string,
                            password: formInfo.password
                        })
                        userStore.setUserInfo({ token })
                        await userStore.updateUserInfo()
                        root.$router.push('/')
                    } catch (error) {
                        showError(error)
                    }
                    submitLoad.value = false
                })
                .catch(() => {})
        }
        return {
            formInfo,
            formRules,
            formRef,
            submitLoad,
            submitForm,
            iconInfo
        }
    }
})
